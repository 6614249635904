<!-- Main Wrapper -->
<div class="main-wrapper login-body">
  <div class="login-wrapper">
    <div class="container">
      <div class="loginbox">
        <div class="login-left">
          <img class="img-fluid" src="assets/img/logo-white.png" alt="Logo" />
        </div>
        <div class="login-right">
          <div class="login-right-wrap">
            <h1>Register</h1>
            <p class="account-subtitle">Access to our dashboard</p>

            <!-- Form -->
            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Name" />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Email"
                  formControlName="email"
                  id="email"
                  [ngClass]="{
                    'is-invalid': f['email'].touched && f['email'].invalid
                  }"
                />
                <div
                  *ngIf="f['email'].touched && f['email'].invalid"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f['email'].errors && f['email'].errors['required']"
                  >
                    Email is required.
                  </div>
                </div>
                <div *ngIf="CustomControler != 0" class="invalid-feedback">
                  {{ CustomControler }}
                </div>
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  type="password"
                  placeholder="Password"
                  formControlName="password"
                  id="password"
                  [ngClass]="{
                    'is-invalid': f['password'].touched && f['password'].invalid
                  }"
                />
                <div
                *ngIf="f['password'].touched && f['password'].invalid"
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    f['password'].errors && f['password'].errors['required']
                  "
                >
                  Password is required.
                </div>
              </div>
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  type="password"
                  placeholder="Confirm Password"
                  formControlName="confirmPassword"
                  id="confirmPassword"
                  [ngClass]="{
                    'is-invalid': f['confirmPassword'].touched && f['confirmPassword'].invalid
                  }"
                />
                <div
                *ngIf="
                  f['confirmPassword'].touched && f['confirmPassword'].invalid
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    f['confirmPassword'].errors &&
                    f['confirmPassword'].errors['required']
                  "
                >
                  Confirm Password is required.
                </div>
              </div>
              <div *ngIf="isvalidconfirmpassword" class="invalid-feedback">
                Password not matching
              </div>
              </div>
              <div class="form-group mb-0">
                <button class="btn btn-primary w-100" 
                 type="submit"
                 [disabled]="form.invalid"
                >
                  Register
                </button>
              </div>
            </form>
            <!-- /Form -->

            <div class="login-or">
              <span class="or-line"></span>
              <span class="span-or">or</span>
            </div>

            <!-- Social Login -->
            <div class="social-login">
              <span>Register with</span>
              <a class="facebook"><i class="fab fa-facebook-f"></i></a
              ><a class="google"><i class="fab fa-google"></i></a>
            </div>
            <!-- /Social Login -->

            <div class="text-center dont-have">
              Already have an account? <a routerLink="/login">Login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Main Wrapper -->
