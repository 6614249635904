<!-- Main Wrapper -->
<div class="main-wrapper login-body">
  <div class="login-wrapper">
    <div class="container">
      <div class="loginbox">
        <div class="login-left">
          <img class="img-fluid" src="assets/img/logo-white.png" alt="Logo" />
        </div>
        <div class="login-right">
          <div class="login-right-wrap">
            <h1>Login</h1>
            <p class="account-subtitle">Access to our dashboard</p>

            <!-- Form -->
            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Email"
                  class="form-control"
                  type="text"
                  formControlName="email"
                  id="email"
                  [ngClass]="{
                    'is-invalid': f['email'].touched && f['email'].invalid
                  }"
                />
                <div
                class="invalid-feedback"
                *ngIf="CustomControler ?.status === 'email'"
              >
                {{ CustomControler.message }}
              </div>
                <div
                  *ngIf="f['email'].touched && f['email'].invalid"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f['email'].errors && f['email'].errors['required']"
                  >
                    Email is required.
                  </div>
                  <div *ngIf="f['email'].errors && f['email'].errors['email']">
                    Please, enter valid email address.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="position-relative">
                <input
                  class="form-control"
                  [type]="Toggledata ? 'password' : 'text'"
                  formControlName="password"
                  id="password"
                  [ngClass]="{
                    'is-invalid':
                      f['password'].touched && f['password'].invalid
                  }"
                  placeholder="Password"
                />
                <span
                [ngClass]="Toggledata == true ? 'fas toggle-password fa-eye' : 'fas toggle-password fa-eye-slash'"
                  (click)="iconLogle()"
                ></span>
              </div>
                <div
                *ngIf="f['password'].touched && f['password'].invalid"
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    f['password'].errors && f['password'].errors['required']
                  "
                >
                  Password is required.
                </div>
              </div>
              <div
                class="invalid-feedback"
                *ngIf="CustomControler ?.status === 'password'"
              >
                {{ CustomControler.message }}
              </div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary w-100" type="submit" [disabled]="form.invalid">
                  Login
                </button>
              </div>
            </form>
            <!-- /Form -->

            <div class="text-center forgotpass">
              <a routerLink="/forgotpassword">Forgot Password?</a>
            </div>
            <div class="login-or">
              <span class="or-line"></span>
              <span class="span-or">or</span>
            </div>

            <!-- Social Login -->
            <div class="social-login">
              <span>Login with</span>
              <a class="facebook"><i class="fab fa-facebook-f"></i></a
              ><a class="google"><i class="fab fa-google"></i></a>
            </div>
            <!-- /Social Login -->

            <div class="text-center dont-have">
              Don’t have an account? <a routerLink="/register">Register</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Main Wrapper -->
